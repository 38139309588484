import { Component, HostListener, ViewChild, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Event as NavigationEvent } from '@angular/router';
import { UserService } from './services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Guid } from 'guid-typescript';
import { OAuthService, NullValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import * as $ from 'jquery';
import { TrackingService } from './services/tracking.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppInsightsService } from './services/appinsights.service';
@Component({
  selector: 'app-root',
  host: { 'class': 'd-flex flex-column h-100' },
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('logoutModel', { static: false }) logoutModel: ElementRef;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  env = environment;
  appInsights: ApplicationInsights;
  public modalOptions: NgbModalOptions;
  idNumber;
  startTime?: Date = null;
  isPwa: boolean;
  private script: HTMLScriptElement;



  constructor(
    private router: Router,
    private userService: UserService,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private trackingService: TrackingService,
    private spinner: NgxSpinnerService,
    private appInsightsService: AppInsightsService,
    private renderer: Renderer2
  ) {

    this.configureAzureAd();
    this.oauthService.tryLoginImplicitFlow();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };

    this.appInsights = appInsightsService.appInsightsObj();
    if (this.cookieService.get('userData') != null && this.cookieService.get('userData') !== '') {
      this.idNumber = JSON.parse(this.cookieService.get('userData')).idNumber;
    }

    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        })
      )
      .subscribe((event: NavigationStart) => {
        setTimeout(() => {
          if (event.url === '/register') {
            //$('.navlinks').hide();
            // this.showNavButtons(window.innerWidth);
          } else {
            //$('.navlinks').show();
          }
        }, 200);
        // if (event.url === '/home' || event.url === '/register') {
        // if (localStorage.getItem('userToken') != null) {
        //   this.logout(() => {
        //     if (event.url.startsWith('/portal')) {
        //       this.router.navigateByUrl('/home');
        //     }
        //   });
        // }
        // }

        if (this.oauthService.getIdentityClaims()) {
          this.reset();
        } else {
          this.idle.stop();
        }

        // tracking service code for track page views.
        if (event instanceof NavigationStart) {
        }

        const isSecure = window.location.origin.startsWith('https');
        //visitor id will create once if not exists
        if (this.cookieService.get('visitorId') == '') {
          const expireVisitor = new Date();
          expireVisitor.setDate(expireVisitor.getDate() + 365);
          this.cookieService.set('visitorId', Guid.create().toString(), expireVisitor, '/', undefined, isSecure, 'Strict');
        }

        //session is depends on time how much time they wanted to | or set session we can also.
        if (sessionStorage.getItem('sessionId') == null) {
          //  this.cookieService.set('sessionId', Guid.create().toString(), null, '/', undefined, isSecure,'Strict');
          sessionStorage.setItem('sessionId', Guid.create().toString());
        }

        if (sessionStorage.getItem('submissionId') == null || sessionStorage.getItem('submissionId') == '') {

          this.addSubmission(isSecure);
        }

      });

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {

        const userId = this.cookieService.get('userId');
        this.appInsights.trackPageView({ properties: { SubmissionId: sessionStorage.getItem('submissionId'), UserId: userId } });
        this.pageViewTrack(new Date());
        if (!window.location.hash) {
          this.trackPageViews();
        }
      }
    });

  }
  trackPageViews() {
    this.trackUserDevice();
    const trackingService = (window as any).trackingService;

    if (trackingService) {
      trackingService.trackPageViews(environment.trackingId);
    } else {
      setTimeout(() => {
        this.trackPageViews();
      }, 100);
    }
  }


  @HostListener('window:resize', [])
  public onResize() {
    if (location.pathname === '/register') {
      //this.showNavButtons(window.innerWidth);
    }
  }

  private showNavButtons(width) {
    if (width > 767) {
      $('.navlinks').hide();
    } else {
      $('.navlinks').show();
    }
  }


  ngOnInit() {


    //tracking script
    let script = this.renderer.createElement('script');
    script.type = 'module';
    script.innerHTML = `import * as tl from "` + environment.trackingUrl + `"
    tl.trackEvent('` + environment.trackingId + `',["span"]);
    window.trackingService = tl;`;
    this.renderer.appendChild(document.head, script);
   
    // sets an idle timeout of 3 seconds, for testing purposes.
    this.idle.setIdle(this.env.sessionTimeOut);
    // sets a timeout period of 3 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      // this.idleState = 'No longer idle.';

      this.idle.stop();
      // this.reset();
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    });

    this.idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout(() => {
        this.idle.stop();
      });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve session gone expire!';

      this.modalService.open(this.logoutModel, this.modalOptions);
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will time out in ' + countdown + ' seconds.';

      if (this.modalService.hasOpenModals) {
        this.idle.clearInterrupts();

      }
    });

    // sets the ping interval to 3 seconds
    this.keepalive.interval(15);
   

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    if (this.oauthService.getIdentityClaims()) {
      // this.idle.watch();
      // this.timedOut = false;
      this.reset();
    } else {
      this.idle.stop();
    }


    this.reset();

  }

  reset() {
    this.idle.watch();
    // xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.modalService.dismissAll();
  }

  stay() {
    this.modalService.dismissAll();
    this.reset();
  }

  logout(callback) {
    this.spinner.show();
    setTimeout(() => {
      if (callback) {
        callback();
      }
      this.modalService.dismissAll();
      (window as any).fcWidget.destroy();

      ///Note on remove Cookies or Storage:- What code you going to apply here for remove Keys, Must apply the same code in(header.component, auth.interceptor)
      this.cookieService.delete('submissionId', '/');
      this.cookieService.delete('uType', '/');
      this.cookieService.delete('userId', '/');
      localStorage.removeItem('isLoginSuccess');
      sessionStorage.clear();
      localStorage.clear();
      //end Note

      this.oauthService.logOut();
      this.spinner.hide();
    }, 1000);
  }
  // private loadOneSignalScript() {
  //   this.script = document.createElement('script');
  //   this.script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
  //   this.script.defer = true;

  //   this.script.onload = () => {
  //     window['OneSignalDeferred'] = window['OneSignalDeferred'] || [];
  //     window['OneSignalDeferred'].push((OneSignal: any) => {
  //       OneSignal.init({
  //         appId: environment.oneSignalAppid,
  //         notifyButton: {
  //           enable: true
  //         },
  //         serviceWorkerParam: { scope: "/oneSignalSW/OneSignalCustomScope/" },
  //         serviceWorkerPath: "/oneSignalSW/OneSignalSDKWorker.js",
  //       });
  //     });
  //   };

  //   document.head.appendChild(this.script);
  // }
  private configureAzureAd() {
    this.oauthService.configure(this.env.azureAdConfig.authConfig as AuthConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocument(this.env.azureAdConfig.discoveryDocumentConfig.url);
    this.oauthService.setStorage(localStorage);
  }

  addSubmission(isSecure) {

    var submissionId = Guid.create().toString();
    sessionStorage.setItem('submissionId', submissionId);
    var submissionParams = {
      BrandName: this.env.appTrack.brandName, PageName: location.pathname, PageUrl: location.href,
      SubmissionId: submissionId, VisitorId: this.cookieService.get('visitorId'),
      SessionId: sessionStorage.getItem('sessionId'), IsMobile: this.trackingService.isMobileDevice(), IsPWA: false
    };
    this.trackingService.submissionPing(submissionParams);

  }

  checkPWA(): boolean {
    try {
      var isPwa = false;
      let deferredPrompt: any;
      window.addEventListener('appinstalled', (event) => {
        try {
          sessionStorage.setItem('isPwaActive', '1');
          isPwa = true;
        } catch (error) {
          console.log('Error setting isPwaActive:', error.message || error);
        }
      });

      function getSamsungBrowserVersion() {
        const ua = navigator.userAgent;
        const match = ua.match(/SamsungBrowser\/([0-9.]+)/);
        return match ? match[1] : null;
      }

      const samsungBrowserVersion = getSamsungBrowserVersion();

      if (window.matchMedia('(display-mode: standalone)').matches) {
        try {
          sessionStorage.setItem('isPwaActive', '1');
          isPwa = true;
        } catch (error) {
          console.error('Error setting isPwaActive:', error.message || error);
        }
      } else if (window.matchMedia('(display-mode: fullscreen)').matches) {
        try {
          sessionStorage.setItem('isFullscreen', '1');
        } catch (error) {
          console.error('Error setting isFullscreen:', error.message || error);
        }
      } else if (window.matchMedia('(display-mode: minimal-ui)').matches) {
        try {
          sessionStorage.setItem('isMinimalUI', '1');
        } catch (error) {
          console.error('Error setting isMinimalUI:', error.message || error);
        }
      } else {
        try {
          sessionStorage.setItem('isBrowser', '0');
        } catch (error) {
          console.error('Error setting isBrowser:', error.message || error);
        }
      }

      if (samsungBrowserVersion) {
        try {
          sessionStorage.setItem('isSamsungBrowser', '1');
          sessionStorage.setItem('samsungBrowserVersion', samsungBrowserVersion);
          sessionStorage.setItem('isPwaActive', '1');
          isPwa = true;
        } catch (error) {
          console.error('Error setting Samsung browser version or isPwaActive:', error.message || error);
        }
      }

    } catch (error) {
      console.log('Error in checkPWA:', error.message || error);
    }
    return isPwa;
  }

  trackUserDevice() {
    var pagePathName = window.location.pathname;
    let source = this.determineDevice();
    console.log(`Tracking event trackUserDevice: SourceUserLog, Name: ${source}, pathname: ${pagePathName}`);
    this.appInsights.trackEvent({
      name: 'DeviceLog'
    }, {
      Type: 'customLog',
      Name: source,
      Value: source,
      Page: pagePathName,
      UserId: this.cookieService.get('userId'),
      SubmissionId: sessionStorage.getItem('submissionId')
    });
  }

  determineDevice(): string {

    const userAgent = navigator.userAgent.toLowerCase(); // Cache the user agent for reuse
    console.log(`Tracking event: userAgent, Name: ${userAgent}`);

    const isMobileDevice = this.isMobileBrowser(userAgent);
    const isPwa = this.checkPWA();
    const isApk = isPwa && this.isAndroidApp(userAgent);
    let source: string;

    if (isApk) {
      source = "APK_GooglePlay_MobileApp"
    }
    else if (isPwa) {
      source = isMobileDevice ? "PWA_mobileDevice" : "PWA_desktopDevice"
    }
    else {
      source = isMobileDevice ? "mobileBrowser" : "desktopBrowser"
    }

    console.log(`Tracking event: SourceUserLog, Name: ${source}, Value: ${source}`);

    return source;
  }
  private isMobileBrowser(userAgent: string): boolean {
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
  }
  private isAndroidApp(userAgent: string): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('env') === 'apkApp';
  }

  pageViewTrack(startime) {
    if (this.env.appTrack.isEnable) {
      var pageViewParams = {
        PageName: location.pathname, PageUrl: location.href,
        SubmissionId: sessionStorage.getItem('submissionId'),
        StartTime: startime, EndTime: new Date(), Duration: (new Date().getTime() - startime.getTime())
        , UserId: this.cookieService.get('userId')
      };
      this.trackingService.pageView(pageViewParams);
    }
  }



}


