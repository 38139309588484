import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommunicationService } from '../services/communication.service';
import { CreditInsightService } from '../services/credit-insight.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Enum } from '../helpers/enums/enum';
@Component({
  selector: 'app-credit-insights',
  templateUrl: './credit-insights.component.html',
  styleUrls: ['./credit-insights.component.css']
})

export class CreditInsightsComponent implements OnInit {
   @ViewChild('callbackSuccessModel', { static: false }) callbackSuccessModel: ElementRef;
  creditInsightModel: any = {};
  creditInsightSummary:any = {};
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  callBackErrorDebt = 'Please try after 15 minutes.';
  closeCallMeBackPopupButtonName: string = "CallMeBackPopupButtonClosed"
  modalOptions: NgbModalOptions;
  campaignSourceType = 8;
  constructor(private creditInsightService: CreditInsightService,    private communicationService: CommunicationService,private modalService: NgbModal) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.GetCreditScoreGroupDetails();
    }, 500);
  }
  
  GetCreditScoreGroupDetails() {

    var data = sessionStorage.getItem('creditInsightModel');
    if (data != null) {
      var sData=JSON.parse(data);
      if(sData!=null){
        this.creditInsightModel = sData?.creditInsightModel;
        this.creditInsightSummary=sData?.creditInsightSummaryDetails
        console.log("Hello world",this.creditInsightSummary)
        }
    }
    else {
      this.creditInsightService.GetCreditInsightDetails().subscribe((data: any) => {
        if(data!=null){
        this.creditInsightModel = data?.creditInsightModel;
        this.creditInsightSummary=data?.creditInsightSummaryDetails
        }
        sessionStorage.setItem('creditInsightModel', JSON.stringify(data));
      },
        (err: HttpErrorResponse) => {
        }
      );
    }
  }
  onResize(_evt: any) { }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 900,
    navText: ['', ''],
    startPosition: 0,
    nav: false,
    autoplay: false,
    autoplayHoverPause: false,
    stagePadding:80,  // Adjust padding if necessary
    margin:10,
    smartSpeed:450,
    responsiveRefreshRate : 1000,
    //autoHeight:true,
    autoWidth: true, // Add autoWidth
    responsive: {
      0: {
        items: 2.4
      },
      400: {
        items: 2.4
      },
      740: {
        items:2.4
      },
      940: {
        items:2.4
      }
    },
  };
 
userCallback() {
  if(this.creditInsightSummary.eligibility=='spl'){
    this.campaignSourceType=Enum.LmsCampaignSourceType.PLEligible;
  }else{
    this.campaignSourceType=Enum.LmsCampaignSourceType.DCEligible;
  }

   
    var payload = {
      CampaignSourceType: this.campaignSourceType,
      WebSource:  "5",
      Referrer:  "JustMoney",
    }
    this.communicationService.userCallbackSource(payload).subscribe((data: any) => {

      $(".txthd").hide();
      $(".modal-footer").hide();
      this.callbackSuccessMsg == '';
      this.callbackSuccessMsg = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions)
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Our coach will contact you shortly.';
      this.callBackErrorDebt = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions)
    });
  }

}
