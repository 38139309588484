<section class="bg-white pb-4 pt-4 pt-md-5 px-0 b5containeradjust d-block d-md-none" id="appcreditinsight"
    *ngIf="creditInsightModel!=null && creditInsightModel?.length>0">
    <div class="">

        <div class="col-12">
            <h3 class="fw-bold text-start px-3"> <i> <img src="../../assets/images/stars.png" alt="" /> </i> Improve
                Your
                Credit Score</h3>
            <p class=" px-3">Discover the key factors that affect your credit score and see how well you do in each. Tap
                each card below for tips.</p>
            <div class="ciSummaryBox">
                <div>
                    <h3 class="insights-heading"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="12"
                            viewBox="0 0 9 12" fill="none">
                            <path
                                d="M0 4.5C0 2.01472 2.01472 0 4.5 0C6.98528 0 9 2.01472 9 4.5C9 5.76843 8.4747 6.91483 7.63077 7.73238C7.47857 7.87983 7.36139 8.03302 7.29095 8.19654L6.71941 9.52336C6.66016 9.6609 6.52476 9.75 6.375 9.75C6.58211 9.75 6.75 9.91789 6.75 10.125C6.75 10.3321 6.58211 10.5 6.375 10.5C6.58211 10.5 6.75 10.6679 6.75 10.875C6.75 11.0821 6.58211 11.25 6.375 11.25L6.20729 11.5854C6.08025 11.8395 5.82055 12 5.53647 12H3.46353C3.17945 12 2.91975 11.8395 2.79271 11.5854L2.625 11.25C2.41789 11.25 2.25 11.0821 2.25 10.875C2.25 10.6679 2.41789 10.5 2.625 10.5C2.41789 10.5 2.25 10.3321 2.25 10.125C2.25 9.91789 2.41789 9.75 2.625 9.75C2.47524 9.75 2.33984 9.6609 2.2806 9.52336L1.70905 8.19654C1.63861 8.03302 1.52143 7.87983 1.36923 7.73238C0.525296 6.91483 0 5.76843 0 4.5ZM4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 5.55714 1.18694 6.51158 1.89107 7.1937C2.088 7.38447 2.27598 7.6169 2.39786 7.89982L2.87178 9H6.12822L6.60214 7.89982C6.72402 7.6169 6.912 7.38447 7.10893 7.1937C7.81306 6.51158 8.25 5.55714 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75Z"
                                fill="#E59A2C" />
                        </svg><span class="ms-2">How Am I Doing?</span></h3>
                </div>

                <div class="insights-body">
                    <p class="fw-bold mb-0">{{creditInsightSummary?.variationHeading}}</p>

                    <p *ngIf="creditInsightSummary?.variationType == 1" class="ciSummaryBody">
                        You are performing well in <span
                            class="fw-bold">{{creditInsightSummary?.numberOfGoodCards}}</span> of the <span
                            class="fw-bold">{{creditInsightSummary?.totalNumberOfCards}}</span> factors that influence
                        your credit score. Keep up the good work to maintain your
                        credit score, and potentially give it a boost.
                    </p>

                    <p *ngIf="creditInsightSummary?.variationType == 2" class="ciSummaryBody">
                        You’re doing okay. Focus on improving the <span
                            class="fw-bold">{{creditInsightSummary?.numberOfBadCards}}</span> factors shown in the
                        highlighted cards below, and your credit score will get
                        a boost.
                    </p>

                    <p *ngIf="creditInsightSummary?.variationType == 3" class="ciSummaryBody">
                        Your credit score can improve. Focus on the <span
                            class="fw-bold">{{creditInsightSummary?.numberOfBadCards}}</span> factors shown in the
                        highlighted cards below, and your credit score will
                        get a boost.
                    </p>

                    <p>Click on each card below to learn more.</p>

                </div>

                <div class="progress" role="progressbar" aria-label="Example with label"
                    [attr.aria-valuenow]="creditInsightSummary?.variationPrecentage" aria-valuemin="0"
                    aria-valuemax="100" [ngStyle]="{'height': '30px'}">
                    <div class="progress-bar"
                        [ngStyle]="{'width.%': creditInsightSummary?.variationPrecentage, 'background-color': '#E59A2C'}">
                        {{ creditInsightSummary?.variationPrecentage }}%</div>
                </div>

                <p class="ciSummaryMsg mt-2">You are doing well in {{ creditInsightSummary?.variationPrecentage }}% of
                    the factors that affect your credit score.</p>
            </div>
            <div class="creditInsightsCarousel py-3 ps-3">
                <!-- <owl-carousel-o (window:resize)="onResize($event)" [options]="customOptions">
                    <ng-template carouselSlide [id]="slide.category"
                        *ngFor="let slide of creditInsightModel; let i = index">
                        <a class="d-flex flex-wrap w-100 mt-auto" href="javascript:void(0)" 
                        href="/portal/credit-insight#{{slide.categoryGroup}}"  id="btnCreditScore-{{slide.category}}">
                            <span *ngIf="slide.isTopPriority" class="categoryImageHolder d-block {{slide.uiClassName}}">
                                <span class="category-caption d-flex w-100 align-items-center text-white justify-content-center">
                                    <i class="me-1">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.70798 11.2076C2.41848 11.3561 2.08998 11.0958 2.14848 10.7636L2.77098 7.21611L0.12874 4.69911C-0.11801 4.46361 0.01024 4.03311 0.340989 3.98661L4.01448 3.46462L5.65247 0.219374C5.80022 -0.0731248 6.19997 -0.0731248 6.34772 0.219374L7.98572 3.46462L11.6592 3.98661C11.99 4.03311 12.1182 4.46361 11.8707 4.69911L9.22922 7.21611L9.85171 10.7636C9.91021 11.0958 9.58171 11.3561 9.29222 11.2076L5.99897 9.5156L2.70798 11.2076Z" fill="#ECECEC"/>
                                        </svg>
                                    </i>
                                    HIGH IMPACT
                                </span>
                                <span class="category-Image d-block">
                                    <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                                id="imgCreditScore-{{slide.category}}" />
                                </span>
                            </span>
                            <span *ngIf="!slide.isTopPriority" class="category-Image d-block">
                                <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                            id="imgCreditScore-{{slide.category}}" />
                            </span>
                        </a>
                    </ng-template>
                </owl-carousel-o> -->


                <div class="scroll-slider d-flex">
                    <div class="slide col-4" *ngFor="let slide of creditInsightModel; let i = index">
                        <a class="d-flex flex-wrap w-100 mt-auto" href="javascript:void(0)"
                            href="/portal/credit-insight#{{slide.categoryGroup}}"
                            id="btnCreditScore-{{slide.category}}">
                            <span *ngIf="slide.isTopPriority" class="categoryImageHolder d-block {{slide.uiClassName}}">
                                <span
                                    class="category-caption d-flex w-100 align-items-center text-white justify-content-center">
                                    <i class="me-1">
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.25771 9.6517C2.01619 9.77584 1.74271 9.55889 1.79133 9.28172L2.30991 6.32579L0.108454 4.22789C-0.0972844 4.03183 0.00921575 3.67335 0.284834 3.6342L3.34638 3.19928L4.71146 0.495191C4.83442 0.251603 5.16753 0.251603 5.2905 0.495191L6.65557 3.19928L9.71712 3.6342C9.99274 3.67335 10.0992 4.03183 9.8935 4.22789L7.69204 6.32579L8.21062 9.28172C8.25925 9.55889 7.98577 9.77584 7.74424 9.6517L5.00098 8.24173L2.25771 9.6517Z"
                                                fill="#ECECEC" />
                                        </svg>
                                    </i>
                                    <span>HIGHEST IMPACT</span>
                                </span>
                                <span class="category-Image d-block">
                                    <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}"
                                        alt="{{slide.category}}" id="imgCreditScore-{{slide.category}}" />
                                </span>
                            </span>
                            <span *ngIf="!slide.isTopPriority" class="category-Image d-block">
                                <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}"
                                    alt="{{slide.category}}" id="imgCreditScore-{{slide.category}}" />
                            </span>
                        </a>
                    </div>

                </div>
            </div>
            <div *ngIf="this.creditInsightSummary.eligibility=='dcquote' || this.creditInsightSummary.eligibility=='spl'"
                class="text-center pt-1 d-flex flex-column">
                <span class="cta-desc"> Got a question about your credit score?</span>
                <button (click)="userCallback()" class="cta-btn ">Chat with a coach</button>
            </div>

        </div>
    </div>
</section>
<ng-template #callbackSuccessModel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Free callback request</h4>
      <button type="button" class="close" name="{{closeCallMeBackPopupButtonName}}" isCloseButton="true"
        data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" class="alert alert-success" role="alert">
      <p>Our coach will contact you shortly.</p>
    </div>
  </ng-template>