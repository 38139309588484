 <!-- Header HTML Start -->
 <app-header></app-header>
 <!-- Header HTML End -->

 <section class="terms">
  <div class="terms-banner b5containeradjust" style="background-image: url(assets/images/profile_header.png);">
    <div class="container ">
        <div class="d-flex flex-wrap align-items-center">
            <div class="col-md-9 col-8 no-space-right">
                <h1 class="page-caption text-white m-0">Privacy Policy</h1>
            </div>
            <div class="col-md-3 text-end col-4">
              <i class="terms-banner-icon">
                <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512" height="40px" viewBox="0 0 512 512" width="40px" class=""><g><g><path d="m446.606 104.394-100-100c-2.813-2.813-6.628-4.394-10.606-4.394h-220c-30.327 0-55 24.673-55 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-342c0-3.978-1.581-7.793-4.394-10.606zm-95.606-53.181 48.787 48.787h-48.787zm45 430.787h-280c-13.785 0-25-11.215-25-25v-402c0-13.785 11.215-25 25-25h205v85c0 8.284 6.716 15 15 15h85v327c0 13.785-11.215 25-25 25z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c"/><path d="m356 202c-18.955 0-35.996-10.532-44.472-27.484l-2.111-4.223c-2.541-5.083-7.735-8.293-13.417-8.293h-80c-5.682 0-10.876 3.21-13.417 8.292l-2.111 4.222c-8.476 16.954-25.517 27.486-44.472 27.486-8.284 0-15 6.716-15 15v85.597c0 33.654 10.619 65.715 30.708 92.716 20.089 27 47.747 46.384 79.982 56.054 1.406.422 2.858.633 4.31.633s2.904-.211 4.31-.633c32.235-9.67 59.893-29.054 79.982-56.054 20.089-27.001 30.708-59.062 30.708-92.716v-85.597c0-8.284-6.716-15-15-15zm-15 100.597c0 54.256-33.971 101.385-85 118.66-51.029-17.275-85-64.404-85-118.66v-71.995c22.701-4.285 42.437-18.2 54.125-38.603h61.75c11.688 20.402 31.424 34.318 54.125 38.603z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c"/><path d="m287.82 268.109-45.962 45.961-17.678-17.677c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l28.284 28.284c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l56.568-56.568c5.858-5.858 5.858-15.355 0-21.213-5.856-5.858-15.354-5.858-21.211 0z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#e59a2c"/></g></g> </svg>
              </i>
            </div>
        </div>  
    </div>
  </div>
  <!-- Home content HTML Start -->
  <div class="terms-hld b5containeradjust pt-md-5 pt-3">
    <div class="container">
      <div class="">
        <div class="col-md-12 px-3 ">
          <div class=" pb-4">
            <ul class="hasnav pb-4">
              <li><a name="btn_InfoCollection_Policy" (click)="scrollToElement(collection)">Information collection</a></li>
              <li><a name="btn_SharingInfo_Policy" (click)="scrollToElement(SharingInformation)">Sharing of information</a></li>
              <li><a name="btn_KeepInfo_Policy" (click)="scrollToElement(keepInformation)">Keep your information</a></li>
              
              <li><a name="btn_RightsAndPrefrences_Policy" (click)="scrollToElement(preferences)">Your rights and preferences</a></li>
              <li><a name="btn_InfoSecurity_Policy" (click)="scrollToElement(quality)">Information security</a></li>
            </ul>
            <h2>JUSTMONEY PRIVACY NOTICE</h2>
            <p>This notice was last updated on 22 November 2024.</p>
            <p>
              This notice tells you how we collect and use your information. We may need to update it periodically, but we will let you know you when we do.
            </p>
            <h2 #collection> We collect and use your personal information</h2>
            <p class="mb-1">When you complete our registration form, we collect personal information directly from you, including:</p>
            
            <ol class="bracket pb-4">
              <li>your name</li>
              <li>contact details such as email address and cell phone number</li>
              <li>your ID number</li>
              <li>your username and password</li>
            </ol>
            <p class="mb-1">When you email, phone or chat with us, we collect and store those messages and conversations. We use this information to:</p>
            <ol class="bracket pb-4">
              <li>confirm your identity before we register you</li>
              <li>provide our services</li>
              <li>communicate with you</li>
              <li>suggest other products and services that may be suitable for your credit profile</li>
              <li>send you marketing communication</li>
              <li>provide support</li>
              <li>analyse, assess and improve our services</li>
              <li>investigate complaints</li>
              <li>provide evidence in disputes</li>
            </ol>
            <p>We collect your credit score and credit report from a credit bureau on your behalf to provide our service.</p>
            <p>We may analyse your credit information to inform you of credit products from other companies that may be suitable for your credit profile. We may send you marketing by email or SMS, or call you about products or services offered by other companies if you ask us to. We may also display a personalised link on our website that directs you to a personalised web page displaying products and services. You may ask us to stop sending you information about other products or services and you may change your notification preferences at any time</p>
            <p  class="mb-1">We collect certain information automatically by using cookies when you visit our website, including</p>
            <ol class="bracket pb-4">
              <li>your device operating system</li>
              <li>access time</li>
              <li>other device data</li>
              <li>advertisements</li>
              <li>other areas of the website you clicked on your location</li>
            </ol>

            <p>We use this information to improve the way our website functions and our service. 
              <br/>
              You can manage cookies through your web browser. Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie, and how to disable existing cookies. You can find out how to do this by clicking &apos;help&apos; on your browser&apos;s menu or by visiting <a name="btn_CookiesInfo_Policy" href="www.allaboutcookies.org">www.allaboutcookies.org</a>. If you disable cookies, it may affect the way you experience our website.
            </p>



            <h2 #SharingInformation>We may share your personal information with our trusted suppliers</h2>
            <p>You are sharing your information with Justmoney. Justmoney is a subsidiary of Intelligent Debt Management (Pty) Ltd and the holding company is IDM Holdings (Pty) Ltd.</p>
            <p>We share your information with a credit bureau to obtain your credit score and credit report and provide our services to you.</p>
            <p>If you ask us to, we may share your information with other companies who advertise on our website.</p>
            <p>We share your information with the service providers who help us deliver our services to you only if they have agreed to protect your information. For instance, we may share your information with other companies in the IDM Group that provide support services and our SMS service providers. Some of our service providers are situated overseas, and we may share your personal information with them.</p>
            <p  class="mb-1">Sometimes we need to disclose your information to a third party:</p>
            <ol class="bracket pb-4">
              <li>if we believe that disclosure is reasonably necessary to comply with the law, legal process, or a government request</li>
              <li>to enforce our contracts and policies</li>
              <li>to protect the security and integrity of our service</li>
              <li>to protect ourselves, clients, and the public from illegal activity</li>
              <li>to respond to an emergency which we believe in good faith requires that we disclose information.</li>
            </ol>

            <p>If there is a change in our company structure or ownership, we may share your information as part of the assets transferred, or the due diligence for that transaction. </p>



                
            <h2 #keepInformation>We do not keep your information longer than we need to</h2>
            <p>We have to keep your personal information for as long as you are our client, or you are subscribed to our notifications. We will not keep your information for longer than we need to for operational purposes, or longer than the law requires.</p>
            
            
            
            <h2 #preferences>Your rights and preferences</h2>
            <p class="mb-1">You have the right to</p>
            <ol class="bracket pb-4">
              <li>ask what personal information we have about you</li>
              <li>ask what personal information we sent to our suppliers, service providers, or anyone else</li>
              <li>ask us to update, correct, or delete any out-of-date or incorrect personal information we have about you</li>
              <li>unsubscribe from any direct marketing communications we may send you</li>
              <li>object to the processing of your personal information</li>
              <li>lodge a complaint about our practices with the Information Regulator.</li>
            </ol>
            <p>It can take us up to 21 days to respond to your request because there are procedures that we need to follow. </p>
            <p>In some instances, we may need proof of your identity, and sometimes we may have additional requirements before we can update your information.</p>

            <h2 #quality>Please notify us if you suspect a security breach</h2>
            <p>We have reasonable security measures in place based on how sensitive the information is. These measures are in place to protect the information from being disclosed, from loss, misuse, and unauthorised access, and from being altered or destroyed.  If you suspect that we (or you) have had a security breach, please let us know immediately by sending an email to <a name="btn_EmailJustmoney_Policy" href="mailto:info@justmoney.co.za">info@justmoney.co.za</a>. Please include as much information as you can.</p>

          </div>
      </div>
      </div>
    </div>
  </div>
 </section>

 <app-footer></app-footer>

